<template>
  <section class="dtc-main-section">
    <vue-tabs>
      <v-tab v-for="(item, i) in titles" :title="item" :key="item">
        <iframe
          v-if="i == 0"
          :src="`${domainObject.recruit}/#/apply17`"
          frameborder="0"
        ></iframe>
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import queryString from "query-string";
import { domainObject } from "@/constant.js";
const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

export default {
  name: "DtcVueTabs105",
  data() {
    return {
      titles: ["公費生公佈欄維護"],
      domainObject,
    };
  },
  components: {
    VueTabs,
    VTab,
  },
  methods: {},
  async mounted() {
    // let arr = Boolean(sessionStorage.functionIds)
    //   ? JSON.parse(sessionStorage.getItem("functionIds"))
    //   : [];
    // arr.includes("10005") ? "" : (this.titles = []);
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  height: 100vh;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
